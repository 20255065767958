import { ExternalLink } from "./Common";
import styles from "./Footer.module.scss";

export default function () {
    return (
        <footer>
            <div className={styles.FooterContent}>
                <div>
                    <h1>There’s more to explore!</h1>
                </div>

                <div>
                    <p>
                        Designed with ❤️ and launched with the help of a friend.
                    </p>
                    <p>
                        If you’ve made it to the bottom and still want to know
                        more, check out my...
                    </p>
                </div>

                <div>
                    <div className={styles.FooterLinks}>
                        <ExternalLink
                            href="https://www.linkedin.com/in/taylor-sihavong"
                            style="Footer"
                        >
                            LinkedIn
                        </ExternalLink>
                    </div>
                </div>
            </div>
        </footer>
    );
}
