import About from "./pages/About";
import Home from "./pages/Home";

import Kheyti from "./pages/Kheyti";
import NuLeaf from "./pages/NuLeaf";
import Agathos from "./pages/Agathos";
import CodeX from "./pages/CodeX";
import ChangeHealthcare from "./pages/ChangeHealthcare";
import PearTherapeutics from "./pages/PearTherapeutics";

import { Navigate } from "react-router-dom";

export const all = [
    "Home",
    "About",
    "Kheyti",
    "Nuleaf",
    "Agathos",
    "Code.X",
    "Change Healthcare",
    "Pear Therapeutics",
] as const;

export type Page = (typeof all)[number];

export function url(page: Page): string {
    switch (page) {
        case "Home":
            return "/";
        case "About":
            return "/about";
        case "Kheyti":
            return "/kheyti";
        case "Nuleaf":
            return "/nuleaf";
        case "Agathos":
            return "/agathos";
        case "Code.X":
            return "/code.x";
        case "Change Healthcare":
            return "/changehealthcare";
        case "Pear Therapeutics":
            return "/peartherapeutics";
    }
}

export function ofUrl(u: string) {
    return all.find((p) => url(p) === u);
}

export function name(page: Page): string {
    return page;
}

export function content(page: Page): JSX.Element {
    switch (page) {
        case "Home":
            return <Home />;
        case "About":
            return <About />;
        case "Code.X":
            return <CodeX />;
        case "Change Healthcare":
            return <ChangeHealthcare />;
        case "Pear Therapeutics":
            return <PearTherapeutics />;
        default:
            return <Navigate to={url("Home")} />;
    }
}
