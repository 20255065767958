import cx from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import styles from "./Project.module.scss";

export function Header(props: { title: string }) {
    return (
        <div className={styles.Header}>
            <h1>{props.title}</h1>
        </div>
    );
}

export namespace ProjectArchive {
    export function Card(
        props: React.PropsWithChildren<{ image: string; linkTo?: string }>
    ): JSX.Element {
        let card = (
            <div
                className={cx(styles.ProjectCard, {
                    [styles.ProjectCard__Link]: !!props.linkTo,
                })}
            >
                <div className={styles.ProjectCard__Top}>
                    <img src={props.image} />
                </div>
                <div className={styles.ProjectCard__Bottom}>
                    {props.children}
                </div>
            </div>
        );

        return props.linkTo ? <Link to={props.linkTo}>{card}</Link> : card;
    }

    export function Component(
        props: React.PropsWithChildren<{
            title: string;
            showArrow?: boolean;
            className?: string;
        }>
    ) {
        return (
            <div className={cx(styles.ProjectArchive, props.className)}>
                <div className={styles.ProjectArchive__Header}>
                    <h1>
                        <span>{props.title}</span>{" "}
                        {props.showArrow ? (
                            <span
                                className={styles.ProjectArchive__Header__Arrow}
                            >
                                {/* TODO: google fonts nunito does not support this arrow */}
                                →
                            </span>
                        ) : (
                            <></>
                        )}
                    </h1>
                </div>
                <div className={styles.ProjectArchive__Cards}>
                    {props.children}
                </div>
            </div>
        );
    }
}

export namespace Reviews {
    export function Card(props: React.PropsWithChildren<{ by: string }>) {
        return (
            <div className={styles.ReviewCard}>
                <div className={styles.ReviewCard__Top}>{props.children}</div>
                <div className={styles.ReviewCard__Bottom}>
                    <p>{props.by}</p>
                </div>
            </div>
        );
    }

    export function Component(props: React.PropsWithChildren<{}>) {
        return (
            <div className={styles.Reviews}>
                <div className={styles.Reviews__Header}>
                    <h1>✨ Reviews ✨</h1>
                </div>
                <div className={styles.Reviews__Cards}>{props.children}</div>
            </div>
        );
    }
}

export namespace Section {
    export function Component(props: React.PropsWithChildren<{}>) {
        return (
            <div className={styles.Section__Component}>{props.children}</div>
        );
    }

    export function Text(props: React.PropsWithChildren<{}>) {
        return <div className={styles.Section__Text}>{props.children}</div>;
    }

    export function TextWithHeader(
        props: React.PropsWithChildren<{
            index: number;
            title: string;
            description: string;
        }>
    ) {
        return (
            <div className={styles.Section__TextWithHeader}>
                <div className={styles.Section__Header}>
                    <div className={styles.Section__Header__Index}>
                        {props.index.toString().padStart(2, "0")}
                    </div>
                    <div className={styles.Section__Header__Title}>
                        {props.title}
                    </div>
                    <div className={styles.Section__Header__Description}>
                        {props.description}
                    </div>
                </div>
                <div className={styles.Section__TextWithHeader__Content}>
                    {props.children}
                </div>
            </div>
        );
    }

    export function BannerTextComponent(
        props: React.PropsWithChildren<{
            index: number;
            title: string;
        }>
    ) {
        return (
            <div className={styles.Section__BannerText}>
                <div className={styles.Section__BannerText__Inner}>
                    <div className={styles.Section__Header}>
                        <div className={styles.Section__Header__Index}>
                            {props.index.toString().padStart(2, "0")}
                        </div>
                        <div className={styles.Section__Header__Title}>
                            {props.title}
                        </div>
                    </div>
                    <div className={styles.Section__BannerText__Content}>
                        {props.children}
                    </div>
                </div>
            </div>
        );
    }

    export function ImageGalleryImage(
        props: React.PropsWithChildren<{
            src: string;
            maxWidth?: string;
            maxHeight?: string;
            border?: boolean;
            shadow?: boolean;
        }>
    ) {
        // return (
        //     <div
        //         className={styles.Section__ImageGallery__Image}
        //         style={{ maxWidth: props.width, height: "auto" }}
        //     >
        //         <img src={props.src} />
        //     </div>
        // );

        return (
            <img
                src={props.src}
                style={{
                    maxHeight: props.maxHeight,
                    maxWidth: props.maxWidth, // TODO: This should be 320px for all images
                    height: "auto",
                    width: "auto",
                }}
                className={cx({
                    [styles.ImageGallery__Image_WithBorder]: props.border,
                    [styles.ImageGallery__Image_WithShadow]: props.shadow,
                })}
            />
        );
    }

    export function ImageGallery(
        props: React.PropsWithChildren<{ caption: string }>
    ) {
        return (
            <div className={styles.Section__ImageGallery}>
                <div className={styles.Section__ImageGallery__Inner}>
                    <div className={styles.Section__ImageGallery__Content}>
                        {props.children}
                    </div>
                    <div className={styles.Section__ImageGallery__Caption}>
                        <p>{props.caption}</p>
                    </div>
                </div>
            </div>
        );
    }

    export function Callout(
        props: React.PropsWithChildren<{
            kind: "ReadMeFirst" | "NoteToSelf";
        }>
    ) {
        return (
            <div className={cx(styles.Section__Callout, styles[props.kind])}>
                <div className={styles.Tag}></div>
                {props.children}
            </div>
        );
    }
}
