import styles from "./Common.module.scss";

import cx from "classnames";

type ExternalLinkStyle = "Intro" | "Content" | "Callout" | "Footer";

export function ExternalLink(
    props: React.PropsWithChildren<{ style: ExternalLinkStyle; href: string }>
) {
    return (
        <a
            className={cx(
                styles.ExternalLink,
                styles[`ExternalLink${props.style}`]
            )}
            href={props.href}
            target="_blank"
            rel="noopener noreferrer"
        >
            {props.children}
        </a>
    );
}
