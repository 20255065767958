import styles from "./CodeX.module.scss";

import * as intro from "../components/Introduction";
import * as project from "../components/Project";

import { ExternalLink } from "../components/Common";

import Cover from "../images/Company_CodeX/0_Cover.png";
import TeamPhoto from "../images/Company_CodeX/0_Team Photo.jpg";
import Amideast from "../images/Company_CodeX/1_Amideast.png";
import AlNayzak from "../images/Company_CodeX/2_AlNayzak.png";
import CFA from "../images/Company_CodeX/3_CFA.png";
import CFP from "../images/Company_CodeX/4_CFP.png";
import CFH from "../images/Company_CodeX/5_CFH.png";
import CFU from "../images/Company_CodeX/6_CFU.png";

function Introduction() {
    return (
        <intro.Section>
            <intro.TextContent>
                <intro.Column>
                    <intro.Part title="TIMELINE">
                        <p>Five years</p>
                        <p>
                            <i>January 2020 - present</i>
                        </p>
                    </intro.Part>
                    <intro.Part title="ROLE">
                        <p>President & Co-founder</p>
                        <p>Design Curriculum Developer</p>
                    </intro.Part>
                    <intro.Part title="COMPANY">
                        <p>
                            <ExternalLink
                                href="https://www.codedotx.org/"
                                style="Intro"
                            >
                                Code.X
                            </ExternalLink>
                        </p>
                    </intro.Part>
                </intro.Column>
                <intro.Column>
                    <intro.Part title="RESPONSIBILITIES">
                        <p>Organization leadership & strategy</p>
                        <p>Volunteer hiring, training, & management</p>
                        <p>International program development & fundraising</p>
                        <p>Client & sponsor relationship management</p>
                        <p>Technical curriculum development</p>
                        <p>Local needfinding & program design</p>
                    </intro.Part>
                </intro.Column>
            </intro.TextContent>

            <intro.Image
                src={Cover}
                className={styles.IntroductionImage}
                border
                shadow
            />
        </intro.Section>
    );
}

function MainContent() {
    return (
        <>
            <project.Section.Component>
                <project.Section.TextWithHeader
                    index={1}
                    title="OVERVIEW"
                    description="Growing a passion project into an international organization"
                >
                    <p>
                        After graduating from university, I co-founded an
                        international youth education 501(c)(3), Code.X
                        (code-dot-ex).
                    </p>
                    <p>
                        My two co-founders and I started Code.X with the belief
                        that{" "}
                        <b>
                            every country deserves to participate in technology
                            innovation
                        </b>{" "}
                        through receiving high-quality technical education,
                        particularly countries often left out of the
                        conversation due to local poverty, lack of education,
                        and conflict.
                    </p>
                    <p>
                        Code.X has since provided cutting-edge technology and
                        design education to thousands of the highest-performing
                        students in the Middle East, North Africa, Balkans,
                        Eastern Europe, and North America, with a focus on
                        students from refugee, orphan, or other low-resource
                        backgrounds.
                    </p>
                    <p>
                        To scale our programming, I have built Code.X's
                        partnerships with major industry names including The
                        Cisco Foundation, Techstars Foundation, Coursera, and
                        Code.org.
                    </p>
                </project.Section.TextWithHeader>
                <project.Section.Callout kind="NoteToSelf">
                    <p>
                        I am incredibly proud of my work here and what my team
                        has accomplished. I have grown so much as a leader,
                        strategist, and community champion by running this
                        organization and applying my human-centered design
                        skills to development abroad. If you’d like to see the
                        stats, check out our{" "}
                        <ExternalLink
                            href="https://gamma.app/docs/Code-for-Palestine-Case-Study-mipjjy7idkgrlzu"
                            style="Callout"
                        >
                            full case study on Code for Palestine.
                        </ExternalLink>
                    </p>
                </project.Section.Callout>
            </project.Section.Component>

            <project.Section.BannerTextComponent
                index={2}
                title="PROJECT GOALS"
            >
                <p>
                    To provide accessible courses that leverage the design and
                    engineering education I received at Stanford, as well as the
                    industry experience from my career as a Silicon Valley
                    product designer, to uplift fragile and conflict-affected
                    communities around the world.
                </p>
                <p>
                    To pay forward the sacrifices that my own family made as
                    refugees in the United States, changing my life with
                    high-quality education.
                </p>
            </project.Section.BannerTextComponent>

            <project.Section.Component>
                <project.Section.TextWithHeader
                    index={3}
                    title="PROJECT BRIEF"
                    description="How we grew from 1 international program to 30+"
                >
                    <p>
                        Code.X grew out of{" "}
                        <ExternalLink
                            href="https://www.codedotx.org/code-for-palestine"
                            style="Content"
                        >
                            Code for Palestine
                        </ExternalLink>
                        , the first programming summer camp to be held in
                        Palestine. Code for Palestine's local sponsor, Paltel
                        Group Foundation, recruited volunteer instructors from
                        Stanford University to lead the program design then
                        teach local high school students in-person over the
                        summer.
                    </p>
                    <p>
                        I volunteered with Code for Palestine in 2018 and 2019
                        as a design instructor with my own classes of 18
                        students in the West Bank and 14 in the Gaza Strip. Many
                        of my students have since gone on to study at
                        high-ranking institutions including Stanford University,
                        MIT, and Brown University.
                    </p>
                    <p>
                        In 2019, my co-founders and I incorporated Code.X with a
                        mission to expand and replicate Code for Palestine's
                        success in similar geographies. As Code.X, we took on
                        co-ownership of Code for Palestine and piloted{" "}
                        <ExternalLink
                            href="https://www.codedotx.org/code-for-albania"
                            style="Content"
                        >
                            Code for Albania
                        </ExternalLink>{" "}
                        and Code for China, our first expansions to new
                        geographies.
                    </p>
                    <p>
                        Our goal is to attract young students to the field of
                        technology, further the standard for youth STEM
                        education, and ultimately create a local culture of
                        empowerment and stronger local tech infrastructure.
                        Under my leadership, Code.X's budget and beneficiary
                        capacity has grown 10x.
                    </p>
                </project.Section.TextWithHeader>
                <project.Section.Callout kind="NoteToSelf">
                    <p>
                        I often refer to Code.X as my “5-9 after my 9-5”; for
                        years now, I have applied the majority of my vacation
                        days and time outside of being a designer to growing our
                        impact abroad. Our earliest program graduates are now
                        entering the workforce and we are still exploring new
                        opportunities through which to inspire young students.
                    </p>
                    <p>
                        I am eternally grateful to the organizations, friends,
                        coworkers, and strangers that have supported me and
                        allowed me to continue this important work.
                    </p>
                </project.Section.Callout>
            </project.Section.Component>

            <project.ProjectArchive.Component title="Some of the magic I've made happen">
                <project.ProjectArchive.Card image={CFU}>
                    <p>
                        <ExternalLink
                            href="https://www.codedotx.org/code-for-ukraine"
                            style="Content"
                        >
                            Code for Ukraine
                        </ExternalLink>
                        , a 12-month tech + design course for orphaned and
                        institutionalized youth across Ukraine, hosted in Lviv
                    </p>
                    <p>(since 2024)</p>
                </project.ProjectArchive.Card>

                <project.ProjectArchive.Card image={AlNayzak}>
                    <p>
                        Workshops for{" "}
                        <ExternalLink
                            href="https://www.codedotx.org/techtalent-workshops"
                            style="Content"
                        >
                            Tech-Talent
                        </ExternalLink>{" "}
                        and{" "}
                        <ExternalLink
                            href="https://www.codedotx.org/horizonsacademy-mentorship"
                            style="Content"
                        >
                            Horizons Academy
                        </ExternalLink>
                        , exposing students across the West Bank to tech +
                        design principles
                    </p>
                    <p>(since 2023)</p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={CFA}>
                    <p>
                        <ExternalLink
                            href="https://www.codedotx.org/code-for-albania"
                            style="Content"
                        >
                            Code for Albania
                        </ExternalLink>
                        , a 24-month tech + design course for high schoolers
                        across Albania, hosted in Korca
                    </p>
                    <p>(since 2019)</p>
                </project.ProjectArchive.Card>

                <project.ProjectArchive.Card image={Amideast}>
                    <p>
                        <ExternalLink
                            href="https://www.codedotx.org/youthcode-gaza"
                            style="Content"
                        >
                            YouthCode-Gaza
                        </ExternalLink>
                        , a tech + design summer camp for high schoolers in the
                        Gaza Strip, hosted in Gaza City
                    </p>
                    <p>(2023)</p>
                </project.ProjectArchive.Card>

                <project.ProjectArchive.Card image={CFP}>
                    <p>
                        <ExternalLink
                            href="https://www.codedotx.org/code-for-palestine"
                            style="Content"
                        >
                            Code for Palestine
                        </ExternalLink>
                        , a 24-month tech + design course for high schoolers
                        across the West Bank & Gaza Strip
                    </p>
                    <p>(2015-2022)</p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={CFH}>
                    <p>
                        <ExternalLink
                            href="https://www.codedotx.org/cfa-code-from-home-2020"
                            style="Content"
                        >
                            Code from Home
                        </ExternalLink>
                        , a remote initiative to make online coding accessible
                        to hundreds of students in Albania during COVID-19
                    </p>
                    <p>(2020)</p>
                </project.ProjectArchive.Card>
            </project.ProjectArchive.Component>

            <project.Reviews.Component>
                <project.Reviews.Card by="Code.X instructor (2023)">
                    <p>
                        I had the privilege of working with Taylor as they
                        brought design thinking and coding education to
                        underserved communities abroad. Their dedication and
                        deep-rooted impact continually impress me. Beyond
                        developing and iterating curriculum, they expertly
                        coordinated instructor travel, established strong
                        community partnerships, and meticulously managed every
                        aspect of the business to ensure a seamless experience
                        for instructors like myself.
                    </p>
                    <p>
                        During the pandemic, Taylor's adaptability and
                        innovative problem-solving ensured that Code.X not only
                        survived but thrived. They swiftly transitioned
                        in-person programs to entirely remote formats,
                        maintaining their commitment to student learning and
                        continuing to provide access in under-resourced
                        conditions. Their resilience and unwavering dedication
                        to their mission are truly inspiring. Taylor’s
                        leadership and passion make them an incredible asset to
                        any team. I highly recommend them for any future
                        endeavors they choose to pursue.
                    </p>
                </project.Reviews.Card>
            </project.Reviews.Component>
        </>
    );
}

export default function () {
    return (
        <div className={styles.CodeX}>
            <project.Header title="Empowering youth from fragile & conflict-affected regions with technology, design, and entrepreneurship programs" />
            <Introduction />
            <MainContent />
        </div>
    );
}
