import { PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";

import * as Pages from "../Pages";

import styles from "./Masthead.module.scss";

import NameBrand from "../vectors/brand.svg";

function Brand() {
    return (
        <div className={styles.Brand}>
            <img src={NameBrand} />
        </div>
    );
}

function Tabs() {
    const location = useLocation().pathname;

    function NavLink(props: PropsWithChildren<{ to: string }>) {
        return (
            <Link to={props.to}>
                <span className={styles.Link}>{props.children}</span>
                <div
                    className={cx({
                        [styles.Tabs__CurrentPageIndicator]:
                            location === props.to,
                    })}
                >
                    <div
                        className={styles.Tabs__CurrentPageIndicator__Dot}
                    ></div>
                </div>
            </Link>
        );
    }

    function MaybeProjectLink() {
        const page = Pages.ofUrl(location);

        return !page || page === "Home" || page === "About" ? (
            <></>
        ) : (
            <NavLink to={location}>{`${Pages.name(page)} /`}</NavLink>
        );
    }

    return (
        <nav>
            <MaybeProjectLink />
            <NavLink to="/">Work</NavLink>
            <NavLink to="/about">About</NavLink>
            <a
                href={`${process.env.PUBLIC_URL}/resume.pdf`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <span className={styles.Link}>Resume</span>
            </a>
            <a
                href="https://www.linkedin.com/in/taylor-sihavong"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span className={styles.Link}>LinkedIn</span>
            </a>
        </nav>
    );
}

export default function () {
    return (
        <header className={styles.Masthead}>
            <Brand />
            <Tabs />
        </header>
    );
}
