import styles from "./PearTherapeutics.module.scss";

import * as intro from "../components/Introduction";
import * as project from "../components/Project";

import EHR from "../images/Company_Pear Therapeutics/Pear - EHR.png";
import Pearforce from "../images/Company_Pear Therapeutics/Pear - Pearforce.png";
import Reports from "../images/Company_Pear Therapeutics/Pear - Reports.png";

import ReSET from "../images/Company_Pear Therapeutics/0_Pear reSET.png";
import Screen1 from "../images/Company_Pear Therapeutics/0_Pear reSET-1.png";
import Screen2 from "../images/Company_Pear Therapeutics/0_Pear reSET-2.png";
import Screen3 from "../images/Company_Pear Therapeutics/0_Pear reSET-3.png";
import { ExternalLink } from "../components/Common";

function Introduction() {
    return (
        <intro.Section>
            <intro.TextContent>
                <intro.Column>
                    <intro.Part title="TIMELINE">
                        <p>One year</p>
                        <p>
                            <i>April 2022 - April 2023</i>
                        </p>
                    </intro.Part>
                    <intro.Part title="ROLE">
                        <p>Senior Product Designer</p>
                    </intro.Part>
                    <intro.Part title="COMPANY">
                        <p>
                            <ExternalLink
                                style="Intro"
                                href="https://www.statnews.com/2023/09/19/pear-therapeutics-medicaid-masshealth-prescription-apps/"
                            >
                                Pear Therapeutics
                            </ExternalLink>
                        </p>
                    </intro.Part>
                </intro.Column>
                <intro.Column>
                    <intro.Part title="RESPONSIBILITIES">
                        <p>
                            Qualitative & quantitative user research, survey
                            design, study recruitment
                        </p>
                        <p>
                            Digital UI/UX design, prototyping, usability testing
                        </p>
                        <p>
                            Customer success strategy, internal training
                            facilitation
                        </p>
                        <p>
                            Product roadmaps, prioritizing feature development,
                            managing engineering work
                        </p>
                    </intro.Part>
                    <intro.Part title="TOOLS">
                        <p>Figma, Miro, Balsamiq, Whimsical</p>
                        <p>Jira, Confluence, Lucidchart, Salesforce</p>
                    </intro.Part>
                </intro.Column>
            </intro.TextContent>

            {/* <intro.ImageGallery
                src={[Screen1, Screen2, Screen3]}
                className={styles.IntroductionImages}
            /> */}
            <intro.Image src={ReSET} className={styles.IntroductionImage} />
        </intro.Section>
    );
}

function MainContent() {
    return (
        <>
            {/* TODO: fix spacing */}
            <project.Section.Component>
                <project.Section.TextWithHeader
                    index={1}
                    title="OVERVIEW"
                    description="Designing at the world's first software-based medicine company"
                >
                    <p>
                        Pear Therapeutics is the pioneering company in U.S.{" "}
                        <ExternalLink
                            href="https://www.primetherapeutics.com/news/prime-therapeutics-and-pear-therapeutics-announce-first-comprehensive-value-based-agreement-for-prescription-digital-therapeutics-reset-and-reset-o-for-the-treatment-of-substance-and-opioi/"
                            style="Content"
                        >
                            Prescription Digital Therapeutics
                        </ExternalLink>
                        , (PDTs), a new class of software-based medicines.
                        Pear's PDTs are prescribed to directly treat specific
                        diseases as part of a larger health treatment plan,
                        tested for safety and efficacy in FDA-evaluated clinical
                        trials.
                    </p>
                    <p>
                        I joined the Pear team to further their groundbreaking
                        work creating and delivering the world's first PDTs with
                        disease treatment claims from the FDA. Like many at
                        Pear, I was motivated by personal experiences of losing
                        loved ones to substance and opioid use.
                    </p>
                    <p>
                        While at Pear, I worked on their patient and provider
                        experience for{" "}
                        <ExternalLink
                            href="https://dtxalliance.org/products/reset-o/"
                            style="Content"
                        >
                            reSET® and reSET-O®
                        </ExternalLink>
                        , two app-based PDTs for the treatment of substance use
                        disorder and opioid use disorder. In a similar fashion
                        to my role at Change Healthcare, I reported to managers
                        across the design and research teams in order to
                        continue leading both types of projects.
                    </p>
                    <p>
                        Over time, I became interested in the intersection of
                        customer success and service design due to the complex
                        nature of Pear's customer journey and product lifecycle.
                        I offered to support our Professional Services team and
                        worked closely with our Director of Customer Success and
                        Director of Solutions Engineering to templatize and
                        digitize our customer onboarding and training processes.
                    </p>
                    <p>
                        I said goodbye to Pear on a high note as the Lead
                        Designer on a huge, scrappy project to build Pear's
                        first Salesforce CRM from scratch, made to centralize
                        customer journey data across our Sales, Medical, and
                        Government teams.
                    </p>
                </project.Section.TextWithHeader>

                <project.Section.Callout kind="NoteToSelf">
                    <p>
                        For my favorite and most challenging project, I designed
                        and ran a patient research study to understand how our
                        therapeutic UI impacted a patient's comprehension of
                        health education and therapy exercises. I spent hours
                        interviewing with substance use and opioid use patients
                        about their experiences with recovery and abstinence
                        from substances. I will always remember these patients'
                        stories.
                    </p>
                </project.Section.Callout>
            </project.Section.Component>

            <project.ProjectArchive.Component title="Some projects that I launched">
                <project.ProjectArchive.Card image={Pearforce}>
                    A centralized Salesforce Customer Relationship Manager
                    (CRM), improving our sales pipeline and client relations
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={Reports}>
                    Customer value reports for clinicians and payors to
                    demonstrate our product value and workflow efficacy,
                    reducing customer churn
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={EHR}>
                    Integrating our prescription order workflows with electronic
                    health records to reduce prescription friction for
                    physicians
                </project.ProjectArchive.Card>
            </project.ProjectArchive.Component>

            <project.Reviews.Component>
                <project.Reviews.Card by="Associate Director - Design (2023)">
                    <p>
                        I had the pleasure of crossing paths and working with
                        Taylor at Pear Therapeutics together as fellow Product
                        Designers on the Product Development team. Taylor was a
                        guiding light for many people, including myself, and
                        projects during our time at Pear — from being a critical
                        team member in the launch (and success) of many
                        provider-facing initiatives to facilitating user
                        research sessions, synthesizing the information gained,
                        and driving design decisions based on those insights.
                        They know what questions to ask and where to dig deeper
                        to build a better product.
                    </p>
                    <p>
                        Taylor's understanding of system design and community
                        design, as well as their experience in service design
                        and product strategy, really shines through in their
                        work; any organization would be lucky to have Taylor on
                        their team.
                    </p>
                </project.Reviews.Card>

                <project.Reviews.Card by="Director - Customer Success (2023)">
                    <p>
                        Having worked design-adjacent for more than a decade of
                        my career, few people have impressed me in product
                        design work as much as Taylor. In just a short
                        timeframe, Taylor learned and synthesized business
                        systems, organizational structures, needs, and
                        constraints across the enterprise in ways that far
                        exceeded tenured leaders in the organization. Their work
                        designing future state business systems was speedy and
                        accurate with a constant emphasis on usability and
                        adoption for internal customers. Taylor has a great
                        attitude and approaches their work with a curiosity and
                        beginner mindset that instantly builds rapport with
                        others. Taylor possesses both the intellect and the work
                        ethic to make anything they work on more valuable than
                        when they found it. I highly recommend Taylor to tackle
                        the fuzzy, the needs innovation, and the stakes are
                        high, impact-work.
                    </p>
                </project.Reviews.Card>

                <project.Reviews.Card by="Chief Product Development Officer (2022)">
                    <p>
                        Congratulations on the launch of our MVP, and thank you
                        for everything Taylor has done to synthesize user needs
                        & design flows of CRM processes to ensure we will have a
                        usable and sticky system and associated processes. I
                        know this was a big project with lots of ambiguity,
                        THANK YOU for your leadership in getting us to this
                        milestone.
                    </p>
                </project.Reviews.Card>

                <project.Reviews.Card by="Director - Design & Research (2022)">
                    <p>
                        Big shoutout to Taylor for their health literacy study
                        work. Taylor did an amazing job moderating the research
                        sessions and putting together a readout of the findings
                        from the sessions. The clear, concise shareout really
                        helps us move quickly in the next phase of polishing the
                        prototypes to prepare it for the next study!
                    </p>
                </project.Reviews.Card>
            </project.Reviews.Component>
        </>
    );
}

export default function () {
    return (
        <div className={styles.PearTherapeutics}>
            <project.Header title="Improving treatment efficacy for patients with substance or opioid use disorder" />
            <Introduction />
            <MainContent />
        </div>
    );
}
