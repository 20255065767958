import styles from "./ChangeHealthcare.module.scss";

import * as intro from "../components/Introduction";
import * as project from "../components/Project";

import Cover from "../images/Company_Change Healthcare/0_Change Healthcare.gif";
import Referrals from "../images/Company_Change Healthcare/1_Referrals.png";
import CustomerSupport from "../images/Company_Change Healthcare/2_Customer Support.png";
import RevenueExcellence from "../images/Company_Change Healthcare/3_Revenue Excellence.png";
import TPL from "../images/Company_Change Healthcare/4_TPL.png";
import SameTime from "../images/Company_Change Healthcare/5_SameTime.png";
import EOB from "../images/Company_Change Healthcare/6_EOB.png";
import HealthApp from "../images/Company_Change Healthcare/7_Health App.png";
import Smartpay from "../images/Company_Change Healthcare/8_Smartpay.png";
import { ExternalLink } from "../components/Common";

function Introduction() {
    return (
        <intro.Section>
            <intro.TextContent>
                <intro.Column>
                    <intro.Part title="TIMELINE">
                        <p>Four years</p>
                        <p>
                            <i>August 2018 - April 2022</i>
                        </p>
                    </intro.Part>
                    <intro.Part title="ROLE">
                        <p>Senior Product Designer</p>
                        <p>UX Specialist</p>
                    </intro.Part>
                    <intro.Part title="COMPANY">
                        <p>
                            <ExternalLink
                                style="Intro"
                                href="https://www.changehealthcare.com/"
                            >
                                Change Healthcare
                            </ExternalLink>
                        </p>
                    </intro.Part>
                </intro.Column>
                <intro.Column>
                    <intro.Part title="RESPONSIBILITIES">
                        <p>Innovation research, qualitative user research</p>
                        <p>
                            Digital UI/UX design, prototyping, usability testing
                        </p>
                        <p>Product and platform strategy</p>
                        <p>Company-wide workshop and training facilitation</p>
                        <p>
                            Company-wide service design and internal design
                            consulting
                        </p>
                    </intro.Part>
                    <intro.Part title="TOOLS">
                        <p>Figma, Sketch, InVision, Abstract</p>
                        <p>Mural, Whimsical</p>
                        <p>Jira, Asana, Trello</p>
                    </intro.Part>
                </intro.Column>
            </intro.TextContent>

            <intro.Image
                src={Cover}
                className={styles.IntroductionImage}
                border
                shadow
            />
        </intro.Section>
    );
}

function MainContent() {
    return (
        <>
            {/* TODO: fix spacing */}
            <project.Section.Component>
                <project.Section.TextWithHeader
                    index={1}
                    title="OVERVIEW"
                    description="Dreaming up the 'next generation' of products for a healthcare giant"
                >
                    <p>
                        Change Healthcare is a large healthcare technology
                        company focusing on delivering innovative solutions for
                        the U.S. healthcare journey (since acquired by
                        UnitedHealth Group in 2022). When I joined in 2018,
                        Change Healthcare had 15,000 employees and over 300
                        healthcare products spanning every inch of the
                        healthcare industry, with one relatively small UI/UX
                        team to work across the user experience of every
                        product.
                    </p>
                    <p>
                        I climbed quickly at Change and was the only IC
                        (individual contributor) on the UI/UX team responsible
                        for leading large-scale research projects alongside
                        large-scale design projects. Our UI/UX team was split
                        into two sub-teams (UX Research and Design) and I
                        reported to both team directors, a set-up that I
                        requested due to my desire to go in-depth on both user
                        research and digital design. This required daily
                        collaboration with both halves of the UI/UX team,
                        product and development leaders, and corporate sponsors.
                    </p>
                    <p>
                        I was known for tackling problems head-on and led
                        projects across over 15 products throughout my four
                        years on the UI/UX team. These projects served
                        essentially all of Change Healthcare’s customer types
                        (healthcare providers, patients/consumers, insurance
                        payors, vendors, internal team members) and required
                        mobile, desktop, web, and print design work.
                    </p>
                    <p>
                        When I ended my time at Change Healthcare, I was focused
                        on innovation design and responsible for enterprise
                        platform development previously led by our Platform &
                        Design Systems Director.
                    </p>
                </project.Section.TextWithHeader>
                <project.Section.Callout kind="NoteToSelf">
                    At Change Healthcare, I proved my versatility and
                    reliability as a researcher and product designer. I am proud
                    of the wide array of projects I worked on, requiring quick
                    orientation and a wide range of expertise that is reflected
                    in the quality of work I delivered to my team and
                    stakeholders.
                </project.Section.Callout>
            </project.Section.Component>

            <project.ProjectArchive.Component title="Some projects that I launched">
                <project.ProjectArchive.Card image={Referrals}>
                    <p>
                        An inbound and outbound referrals management tool for
                        healthcare providers and patients, allowing patients to
                        access faster care via referrals
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={CustomerSupport}>
                    <p>
                        A redesign for{" "}
                        <ExternalLink
                            href="https://support.changehealthcare.com/"
                            style="Content"
                        >
                            Change Healthcare’s customer support website
                        </ExternalLink>
                        , lowering the amount of time it takes for customer
                        questions to be answered
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={RevenueExcellence}>
                    <p>
                        Industry trend forecasting and innovation research
                        focused on Revenue Cycle Management, setting company
                        research direction for the next 10 years
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={TPL}>
                    <p>
                        A full legacy system redesign for healthcare claim
                        auditors and payment processors, improving claim audit
                        and reimbursement accuracy
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={SameTime}>
                    <p>
                        A tablet consumer payment concept for patients to pay
                        for health services at point-of-care, lowering the risk
                        of missed care payments
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={EOB}>
                    <p>
                        A print redesign for a white-label Explanation of
                        Benefits, improving patient comprehension of insurance
                        benefits
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={HealthApp}>
                    <p>
                        A patient mobile app to manage health records and
                        appointments, for patients to take charge of their
                        healthcare data
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={Smartpay}>
                    <p>
                        A web app for patients managing bill payment for
                        healthcare procedures, streamlining the bill payment
                        process
                    </p>
                </project.ProjectArchive.Card>
            </project.ProjectArchive.Component>

            <project.Reviews.Component>
                <project.Reviews.Card by="UX Researcher (2022)">
                    <p>
                        Taylor is a passionate, creative, and dedicated
                        designer. We worked together on a small cross-functional
                        team and I was impressed with Taylor's commitment and
                        steadfastness. Throughout the project, Taylor led
                        multiple design initiatives, quickly adapted to
                        unexpected changes, and made crucial contributions to
                        internal platform design at Change Healthcare – a high
                        profile initiative to support cloud service delivery and
                        defragment the healthcare system. Throughout the
                        project, Taylor demonstrated advanced design skills,
                        positive energy, efficiency, and a keen understanding of
                        human centered design. I highly recommend Taylor to any
                        team in need of an exceptional product designer.
                    </p>
                </project.Reviews.Card>
                <project.Reviews.Card by="VP – User Experience (2020)">
                    <p>
                        Taylor is both a UX researcher and a designer, and they
                        excel at both. They were recently assigned to a
                        strategic project that required both of these skills,
                        but as the project is in the discovery phase, much of
                        the "work to be done" was centered around user
                        interviews. As such, Taylor participated in conducting
                        25+ one-hour user interviews as an interviewer and
                        notetaker, then analyzed insights across these sessions.
                        Taylor looked for commonalities across workflows and
                        roles, then summarized needs and pain points in an
                        interim report that informs an emerging point of view on
                        the challenges of today and opportunities for the
                        future. All of this within a very short period of time.
                        But what was most amazing is how they masterfully
                        organized the insights - creating new tools and
                        approaches to do so; and then figured out how to take
                        the massive amount of information and put it into a
                        summary report that helps build foundational knowledge
                        among the project team.
                    </p>
                    <p>
                        Taylor’s ability to think creatively, problem solve, be
                        incredibly thorough, roll up their sleeves and crank
                        through the data, and lean-in... promotes innovation and
                        opens up future possibilities for the company.
                    </p>
                </project.Reviews.Card>
                <project.Reviews.Card by="VP – User Experience (2019)">
                    <p>
                        Taylor Sihavong is the first one that most every team
                        requests – as they conduct UX research and deliver
                        visual and interactive design with the highest level of
                        standard. They holds themselves accountable and sets an
                        example for others to follow. What makes them
                        extraordinary is that they are also very generous in
                        reaching out and helping others to do the same –
                        prioritizing the success of the team and project as a
                        whole in delivering the very best for our customers.
                    </p>
                </project.Reviews.Card>
            </project.Reviews.Component>
        </>
    );
}

export default function () {
    return (
        <div className={styles.ChangeHealthcare}>
            <project.Header title="Designing the next generation of healthcare products for an industry leader" />
            <Introduction />
            <MainContent />
        </div>
    );
}
