import { PropsWithChildren } from "react";
import cx from "classnames";

// import stylesDefault from "./Introduction.module.scss";
import styles from "./Introduction.module.scss";
import stylesCodeX from "../pages/CodeX.module.scss";
import stylesNuleaf from "../pages/NuLeaf.module.scss";
import stylesAgathos from "../pages/Agathos.module.scss";

// const styles = (() => {
//     const allStyles = [
//         stylesDefault,
//         stylesNuleaf,
//         stylesAgathos,
//         stylesCodeX,
//     ] as {
//         [key: string]: string;
//     }[];
//     const allNamesPerStyle = {} as { [key: string]: string[] };

//     for (const s of allStyles) {
//         for (const [key, value] of Object.entries(s)) {
//             if (!(key in allNamesPerStyle)) allNamesPerStyle[key] = [];

//             allNamesPerStyle[key].push(value);
//         }
//     }

//     const combined = {} as { [key: string]: string };
//     for (const [key, value] of Object.entries(allNamesPerStyle)) {
//         combined[key] = cx(value);
//     }

//     type Selector = keyof (typeof stylesDefault &
//         typeof stylesNuleaf &
//         typeof stylesAgathos &
//         typeof stylesCodeX);
//     return combined as { [key in Selector]: string };
// })();

export function Part(props: PropsWithChildren<{ title: string }>) {
    return (
        <div className={styles.Part}>
            <p className={styles.Part__Title}>{props.title}</p>
            <div className={styles.Part__Content}>{props.children}</div>
        </div>
    );
}

export function TextContent(props: PropsWithChildren<{}>) {
    return <div className={styles.TextContent}>{props.children}</div>;
}

export function Column(props: PropsWithChildren<{}>) {
    return <div className={styles.Column}>{props.children}</div>;
}

export function Image(props: {
    src: string;
    className?: string;
    border?: boolean;
    shadow?: boolean;
}) {
    return (
        <div className={cx(styles.Image, props.className)}>
            <img
                src={props.src}
                className={cx({
                    [styles.Image_WithBorder]: props.border,
                    [styles.Image_WithShadow]: props.shadow,
                })}
            />
        </div>
    );
}

export function ImageGallery(props: { src: string[]; className?: string }) {
    return (
        <div className={cx(styles.ImageGallery, props.className)}>
            {props.src.map((src) => (
                <Image src={src} />
            ))}
        </div>
    );
}

export function Section(props: PropsWithChildren<{}>) {
    return (
        <div className={styles.Introduction}>
            <div className={styles.Content}>{props.children}</div>
        </div>
    );
}
