import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration,
} from "react-router-dom";

import styles from "./App.module.scss";

import Footer from "./components/Footer";
import Masthead from "./components/Masthead";

import * as Pages from "./Pages";

const router = createBrowserRouter(
    createRoutesFromElements([
        <Route element={<Layout />}>
            {Pages.all.map((p, i) => (
                <Route path={Pages.url(p)} element={Pages.content(p)} key={i} />
            ))}
            ,
            <Route path="/*" element={<Navigate to="/" />} />
        </Route>,
    ])
);

function Layout() {
    return (
        <div className={styles.Root}>
            <div className={styles.App}>
                <Masthead />

                <article className={styles.Content}>
                    <Outlet />
                </article>

                <Footer />
            </div>

            <ScrollRestoration />
        </div>
    );
}

// TODO: fix favicon to be centered
export default function () {
    return <RouterProvider router={router} />;
}
